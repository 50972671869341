<template>
  <div>
    <div class="_BoxHeader">
      <span>风控题库维护</span>
    </div>
    <div class="PageContain">
      <div class="_SelectHeader select-header">
        <span class="_Title">列表信息</span>
        <div>
          <el-button type="primary" @click="AMQuestion(true)"
            >添加题库</el-button
          >
          <el-button
            class="_ImgBtn"
            icon="el-icon-delete"
            @click="deleteItem"
          ></el-button>
        </div>
      </div>
      <div class="_Table">
        <span style="margin-left: 15px">公司：</span>
        <el-select v-model="company" clearable @change="bindCompany" placeholder="请选择">
          <el-option
            v-for="item in companyList"
            :key="item.value"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <span style="margin-left: 15px"> 部门：</span>
        <el-select v-model="dept" clearable placeholder="请选择">
          <el-option
            v-for="item in deptList"
            :key="item.value"
            :label="item.deptmentName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <span style="margin-left: 15px">文件名称：</span>
        <el-input
          style="width: 23%"
          clearable
          @clear="curIndex = []"
          placeholder="请输入文件名称"
          v-model="searchFileName"
        />
        <span style="margin-left: 10px"></span>
        <el-button type="primary" @click="getQuestionInfo()"
          >搜索</el-button
        >
        <el-table
          :data="tableData.slice(start, end)"
          style="width: 100%; margin-top: 20px"
          :height="elementHeight"
          :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
          border
          ref="tableData"
          :row-class-name="tableRowClassName"
          :row-style="selectedstyle"
          @row-click="rowClick"
          id="tableData"
        >
          <el-table-column type="index" label="序号"> </el-table-column>
          <el-table-column prop="fileName" label="文件名称" width="580">
          </el-table-column>
          <el-table-column prop="fileNo" label="文件编号"> </el-table-column>
          <el-table-column prop="fileQuestionsCount" label="问题数量">
            <template slot-scope="scope">
              <div style="color: blue" @click="openQuestion(scope.row.fileNo)">
                <span>{{ scope.row.fileQuestionsCount }}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div class="total">
          文件总数： <span>{{ tableData.length }}</span> 问题总数：
          <span>{{ total }}</span>
        </div>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="curSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        style="
          position: relative;
          text-align: center;
          padding-top: 10px;
          width: 100%;
          right: 0;
        "
      >
      </el-pagination>
    </div>
    <Dialog
      :title="isAdd ? '添加题库' : '修改题库'"
      width="50%"
      top="25vh"
      :showDialog="showDialog"
      class="dialog"
      @handleReqData="handleReqData"
      @handleClose="handleClose"
      :showTowText="true"
    >
      <div class="contaion">
        <div>
          <div>
            <div class="_Inp1">
              <span style="margin-top: 10px"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司：</span
              >
              <el-select
                v-model="dialogCompy"
                placeholder="请选择公司"
                @change="changDialogCompy"
              >
                <el-option
                  v-for="item in companyList"
                  :key="item.value"
                  :label="item.companyName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="_Inp1">
              <span style="margin-top: 10px"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;部门：</span
              >
              <el-select
                v-model="dialogDept"
                placeholder="请选择部门"
                @change="changDialogDept"
              >
                <el-option
                  v-for="item in dialogDeptList"
                  :key="item.value"
                  :label="item.deptmentName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="_Inp1">
              <span style="margin-top: 10px"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;岗位：</span
              >
              <el-select
                v-model="dialogPost"
                placeholder="请选择岗位"
                @change="changDialogPost"
              >
                <el-option
                  v-for="item in dialogPostList"
                  :key="item.id"
                  :label="item.postName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="_Inp">
              <span>文件编号：</span>
              <el-input placeholder="请输入文件编号" v-model="fileNo">
              </el-input>
            </div>
            <div class="_Inp">
              <span>文件名称：</span>
              <el-input placeholder="请输入文件名称" v-model="fileName">
              </el-input>
            </div>
          </div>
          <div>
            <div class="_Inp">
              <span>风控流程：</span>
              <el-input placeholder="请输入风控流程" v-model="riskName">
              </el-input>
            </div>
            <div class="_Inp">
              <span>风险点：</span>
              <el-input placeholder="请输入风险点" v-model="riskPoint">
              </el-input>
            </div>
            <div class="_Inp">
              <span>风险应对措施：</span>
              <el-input placeholder="请输入风险应对措施" v-model="riskResponse">
              </el-input>
            </div>
            <!-- <div class="_Inp">
              <span>版本号：</span>
              <el-input placeholder="请输入版本号" v-model="version">
              </el-input>
            </div> -->
          </div>
        </div>
      </div>
      <div style="padding-left: 38px; padding-top: 15px">
        <el-upload
          action=" https://jsonplaceholder.typicode.com/posts/"
          list-type="picture-card"
          ref="upload"
          :on-preview="handlePictureCardPreview"
          :http-request="uploadFile"
          :headers="token"
          :key="uploadKey"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            :preview-src-list="srcList"
            @mouseover="overimg(index)"
          ></el-image>
          <i
            style="color: red; margin-right: 5px"
            class="el-icon-delete-solid"
            v-show="index === Curindex"
            @click="delImg(item)"
          ></i>
        </span>
      </div>
    </Dialog>
    <el-dialog fullscreen :visible.sync="dialogQuestion">
      <div slot="title" class="header-title">
        <span class="title-name" @click="dialogQuestion = false">
          返回 题库
        </span>
      </div>
      <div class="qustionBox">
        <el-button class="_ImgBtn" @click="exportExcel"
          ><img src="../../assets/images/img1/import2.png" />导出</el-button
        >
        <span>
          <img
            style="
              cursor: pointer;
              width: 30px;
              height: 30px;
              margin-left: 10px;
            "
            @click="delQuestionList"
            src="../../assets/images/Examine_img/del.png"
            alt=""
          />
        </span>
        <el-button
          style="margin-left: 10px"
          class="_ImgBtn"
          @click="delQuestionList"
          ><i class="el-icon-delete i-popover"> </i>批量删除</el-button
        >
        <el-button
          style="margin-left: 10px"
          class="_ImgBtn"
          @click="AMQuestion(false)"
          ><i class="el-icon-edit i-popover"> </i
        ></el-button>
      </div>
      <el-table
        :data="questionList"
        v-loading="loading"
        id="questionList"
        :height="elementHeight + 100"
        style="width: 100%"
        :header-cell-style="{
          background: '#E8EBF4',
          color: '#5C5C5C',
          borderColor: '#C0C0C0',
        }"
        border
        :row-style="dSelectedstyle"
        @select="selectRow"
        @row-click="dRowClick"
        :row-class-name="tableRowClassName"
      >
        <el-table-column type="selection" width="80"> </el-table-column>
        <el-table-column prop="compName" label="公司" width="180">
        </el-table-column>
        <el-table-column prop="deptName" label="部门"> </el-table-column>
        <el-table-column prop="postName" label="岗位"> </el-table-column>
        <el-table-column prop="fileNo" label="文件编号"> </el-table-column>
        <el-table-column prop="fileName" label="文件名称"> </el-table-column>
        <el-table-column prop="riskName" width="180" label="风控流程">
        </el-table-column>
        <el-table-column prop="riskPoint" width="180" label="风险点">
        </el-table-column>
        <el-table-column prop="riskResponse" width="180" label="风险应对措施">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogQuestion = false">取 消</el-button>
        <el-button type="primary" @click="dialogQuestion = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
 
<script>
import { get, post, deleteMethod, put, baseURL } from '../../api/http'
import Dialog from '../../components/dialog/Dialog'
import { compressImage } from '../../modules/CompressImage'
import imgTypeChange from '../../modules/imgChange'
import XLSX from 'xlsx'
import FileSaver from 'file-saver'

export default {
  components: { Dialog },
  data() {
    return {
      BUrl: baseURL[process.env.NODE_ENV + ''],
      company: '',
      companyList: [],
      dept: '',
      deptList: [],
      searchFileName: '',
      rowItem: {},
      tableRowIndex: null,
      tableData: [],
      total: 0,
      totalNumber: 0,
      showDialog: false,
      dialogCompy: '',
      dialogDept: '',
      dialogPost: '',
      dialogPostList: [],
      dialogDeptList: [],
      fileNo: '',
      fileName: '',
      riskName: '',
      riskPoint: '',
      riskResponse: '',
      version: '',
      dialogfileNo: '',
      delQuestion: [],
      questionList: [],
      dialogQuestion: false,
      loading: false,
      dTableRowIndex: null,
      imgList: [],
      srcList: [],
      Curindex: null,
      uploadKey: 1,
      postImgList: [],
      currentPage: 1,
      start: 0,
      end: 10,
      curSize: 10,
    }
  },
  mounted() {
    this.getElementHeight()
    this.getComPanyList()
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('tableData').offsetTop + 60 + 102)
      })
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          'background-color': '#E2E8FA !important',
          'font-weight': 'bold !important',
          color: '#555555',
        }
      }
    },
    rowClick(row) {
      console.log(row)
      this.rowItem = row
      this.tableRowIndex = row.index
    },
    getComPanyList() {
      get('/api/Company/GetAllByUser').then((res) => {
        this.companyList = res.data
        this.company = JSON.parse(
          sessionStorage.getItem('userInfo')
        ).upCompanyId
        this.getDeptList()
        this.getQuestionInfo()
      })
    },
    bindCompany(e) {
      this.dept = ''
      this.deptList = []
      if (!e) return
      this.company = e
      this.getDeptList()
    },
    getDeptList() {
      get(
        '/api/Department/GetNoTree?CompId=' +
          (!this.showDialog ? this.company : this.dialogCompy)
      ).then((res) => {
        if (res.code == 200) {
          !this.showDialog
            ? (this.deptList = res.data)
            : (this.dialogDeptList = res.data)
        }
      })
    },
    getQuestionInfo() {
      get(
        '/api/RiskQuestionPool/GetQuestionByFile?CompId=' +
          this.company +
          '&DeptId=' +
          this.dept +
          '&FileNo=&FileName=' +
          this.searchFileName
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.total = 0
            this.totalNumber = 0
            this.tableData = []
            resp.data.map((item) => {
              this.total += item.fileQuestionsCount
            })
            this.tableData = resp.data
            this.totalNumber = resp.count
            this.tableRowIndex = null
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('失败')
        })
    },
    handleClose() {
      this.showDialog = false
    },

    AMQuestion(isAdd) {
      this.isAdd = isAdd
      if (!isAdd && this.dTableRowIndex == null) {
        this.$message({
          type: 'warning',
          message: '未选择列表',
        })
        return
      }
      this.showDialog = true
      this.dialogCompy = isAdd ? '' : this.rowItem.compId
      this.dialogDept = isAdd ? '' : this.rowItem.deptId
      this.dialogPost = isAdd ? '' : this.rowItem.postId
      this.dialogDeptList = []
      this.dialogPostList = []
      this.fileNo = isAdd ? '' : this.rowItem.fileNo
      this.fileName = isAdd ? '' : this.rowItem.fileName
      this.riskName = isAdd ? '' : this.rowItem.riskName
      this.riskPoint = isAdd ? '' : this.rowItem.riskPoint
      this.riskResponse = isAdd ? '' : this.rowItem.riskResponse
      this.version = isAdd ? '' : this.rowItem.version
      if (!this.isAdd) {
        this.getDeptList()
        this.changDialogDept(this.rowItem.deptId)
        this.getimg()
      } else if (this.srcList.length != 0) {
        this.srcList = []
        this.uploadKey++
        this.$refs.upload.uploadFiles = []
      }
    },
    changDialogCompy(e) {
      this.dialogCompy = e
      this.dialogDept = ''
      this.getDeptList()
    },
    changDialogDept(e) {
      this.dialogDept = e
      get('/api/Post?CompId=' + this.dialogCompy + '&DeptId=' + e).then(
        (res) => {
          this.dialogPostList = res.data
        }
      )
    },
    changDialogPost(e) {
      this.dialogPost = e
    },
    handleReqData() {
      var data = {
        id: this.isAdd ? 0 : this.rowItem.id,
        compId: this.dialogCompy,
        deptId: this.dialogDept,
        postId: this.dialogPost,
        fileNo: this.fileNo,
        fileName: this.fileName,
        riskName: this.riskName,
        riskPoint: this.riskPoint,
        riskResponse: this.riskResponse,
        version: this.version,
        isDelete: 0,
        saveTime: new Date().toISOString(),
        saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
      }
      var date = data.saveTime
      var method = this.isAdd ? post : put
      method(
        this.isAdd ? '/api/RiskQuestionPool' : '/api/RiskQuestionPool',
        data
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: this.isAdd ? '添加成功' : '修改成功',
            })
            this.getQuestionInfo()
            this.showDialog = false
            if (!this.isAdd) {
              this.openQuestion(this.dialogfileNo)
              this.getimg()
            }
            if (this.postImgList.length != 0 && this.isAdd) {
              this.getRiskItem(date)
            }
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error(this.isAdd ? '添加失败' : '修改失败')
        })
    },
    getRiskItem(date) {
      get(
        '/api/RiskQuestionPool/GetByFile?FileNo=' +
          this.fileNo +
          '&FileName=' +
          this.fileName
      ).then((resp) => {
        if (resp.code == 200) {
          resp.data.map((i) => {
            if (date == i.saveTime) {
              this.postImg(i)
            }
          })
        }
      })
    },

    deleteItem() {
      if (this.tableRowIndex == null) return
      this.$confirm('您确认要删除“' + this.rowItem.fileName + '”这个文件？')
        .then(() => {
          deleteMethod(
            '/api/RiskQuestionPool/DeleteByFile?FileNo=' +
              this.rowItem.fileNo +
              '&FileName=' +
              this.rowItem.fileName
          )
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功',
                })
                this.tableData.splice(this.tableRowIndex, 1)
                this.tableRowIndex = null
                this.rowItem = {}
              } else {
                this.$message.error(resp.message)
              }
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {})
    },
    openQuestion(v) {
      this.dialogfileNo = v
      if (!this.company && this.isAdd) {
        this.$message({
          type: 'warning',
          message: '请选择公司部门',
        })
        return
      }
      this.questionList = []
      this.dTableRowIndex = null
      get(
        '/api/RiskQuestionPoolUpt/RiskQuestionPoolExport?CompId=' +
          this.company +
          '&DeptId=' +
          this.dept +
          '&FileNo=' +
          this.dialogfileNo
      ).then((res) => {
        this.questionList = res.data
        this.dialogQuestion = true
      })
    },
    exportExcel() {
      var wb = XLSX.utils.table_to_book(document.querySelector('#questionList'))
      wb.Sheets.Sheet1['!cols'][0] = { hidden: true }
      var wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array',
      })
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: 'application/octet-stream' }),
          '风控题库维护.xlsx'
        )
      } catch (e) {
        if (typeof console !== 'undefined') console.log(e, wbout)
      }
    },
    selectRow(e) {
      this.delQuestion = e
    },
    delQuestionList() {
      if (this.delQuestion.length == 0) {
        this.$message({
          type: 'warning',
          message: '未选择文件',
        })
        return
      }
      this.$confirm('此操作将永久删除该文件', '是否继续?', '提示').then(() => {
        this.loading = true
        var idList = []
        this.delQuestion.forEach((element) => {
          idList.push({ QuestionId: element.id })
        })
        post('/api/RiskQuestionPool/BatchDelete', {
          data: JSON.stringify(idList),
        }).then((res) => {
          if (res.code == 200) {
            this.loading = false
            this.$message({
              type: 'success',
              message: res.message,
            })
            this.delQuestion.forEach((element) => {
              this.questionList.forEach((i, index) => {
                if (i.id == element.id) {
                  this.questionList.splice(index, 1)
                }
              })
            })
            this.delQuestion = []
            if (this.questionList.length == 0) this.getQuestionInfo()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    dRowClick(row) {
      this.rowItem = row
      this.dTableRowIndex = row.index
    },
    dSelectedstyle({ rowIndex }) {
      if (this.dTableRowIndex == rowIndex) {
        return {
          'background-color': '#E2E8FA !important',
          'font-weight': 'bold !important',
          color: '#555555',
        }
      }
    },
    handlePictureCardPreview() {
      this.dialogVisiblebig = true
    },
    async uploadFile(e) {
      if (e.file) {
        let file = await imgTypeChange(e.file)
        let newfile = await compressImage(file)
        if (newfile.size > 10000) {
          newfile = await compressImage(newfile)
        }
        this.uploadFileReq(newfile)
      }
    },
    async uploadFileReq(file) {
      var formData = new FormData()
      formData.append('files', file)
      await post('/api/UpLoadFiles/UploadImg', formData)
        .then((res) => {
          if (res.code == 200) {
            this.postImgList.push(res.value)
            if (!this.isAdd) this.postImg(this.rowItem)
          } else {
            this.uploadKey++
            this.$refs.upload.uploadFiles = []
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          this.$refs.upload.uploadFiles = []
          this.uploadKey++
          this.$message.error('上传失败')
        })
    },
    postImg(item) {
      var index = this.postImgList.length
      this.postImgList.map((value) => {
        post('/api/RiskQuestionAndImage', {
          id: 0,
          riskQuestionId: item.id,
          imageAddress: this.BUrl + '/uploads/' + value,
          saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
          saveTime: new Date().toISOString(),
        }).then(() => {
          index--
          if (index == 0) {
            this.postImgList = []
            this.uploadKey++
            this.$refs.upload.uploadFiles = []
            if (!this.isAdd) this.getimg()
          }
        })
      })
    },
    getimg() {
      if (this.srcList.length != 0) {
        this.srcList = []
        this.$refs.upload.uploadFiles = []
        this.uploadKey++
      }
      get(
        '/api/RiskQuestionAndImage/GetByQuestionID?questionId=' +
          this.rowItem.id
      ).then((res) => {
        if (res.code == 200) {
          this.imgList = res.value
          if (res.value.length == 0) return
          res.value.forEach((element) => {
            this.srcList.push(element.picNameAndAddr)
          })
          this.rowItem.imagesCount = this.srcList.length
        }
      })
    },
    overimg(index) {
      this.Curindex = index
    },
    delImg(item) {
      this.imgList.forEach((e) => {
        if (e.picNameAndAddr == item) {
          this.$confirm('此操作将永久删除该图片', '是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              deleteMethod('/api/RiskQuestionAndImage?Id=' + e.id).then(() => {
                this.getimg()
              })
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除',
              })
            })
        }
      })
    },
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
  },
}
</script>

<style  lang="less" scoped>
.dialog ._Select {
  width: 100% !important;
}
.dialog .el-select {
  width: 100%;
  margin-top: 10px;
}
.dialog .contaion > div:nth-of-type(1) {
  display: flex;
}
.dialog .contaion > div:nth-of-type(1) div {
  padding-right: 30px;
}
.dialog ._Select > span,
.dialog ._Inp > span {
  width: 170px;
}
.select-header {
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e9e7e8;
  padding-top: 10px;
  > div {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
}
/* 上传图片 */
/deep/ .el-upload--picture-card {
  width: 100px !important;
  height: 100px !important;
  line-height: 100px !important;
}
/deep/ .el-dialog__body {
  padding: 30px 20px !important;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
  margin-right: 4px;
  margin-top: 4px;
}
/deep/.el-dialog__body {
  padding: 10px 20px !important;
}
._Inp1 .el-select {
  width: 75%;
}
._Inp .el-select {
  margin-top: 0;
  height: auto !important;
  display: flex !important;
}
._Inp .el-select > span {
  width: auto !important;
}
._Inp1 {
  margin-left: 11.3%;
  display: flex;
  align-items: center;
  justify-content: center;
}
._Inp1 input {
  display: flex;
  height: auto;
}
._Inp1 span {
  white-space: nowrap;
}
._Table {
  overflow: auto;
}
.header-title {
  display: flex;
  align-items: center;
  color: white;
}
.qustionBox {
  display: flex;
  margin-bottom: 5px;
}
.total {
  position: absolute;
  right: 20px;
  padding-right: 20px;
  padding-top: 20px;
  font-weight: bold;
  cursor: pointer;
  > span {
    color: red;
    font-size: 13px;
  }
}
.activefileNo {
  color: red !important;
}
.ImgDel {
  position: relative;
}

.ImgDel i {
  transform: scale(1.5);
  position: absolute;
  top: -500%;
  right: 0;
  cursor: pointer;
}
</style>